import {React, useState, useContext, Context, createContext, useEffect} from 'react';
import PropTypes from 'prop-types';

import BrunisbergSmallRgbB from '../../assets/logos/brunisberg/Brunisberg_Small_RGB_B.svg';
import KreuzstiftSmallRgbB from '../../assets/logos/kreuzstift/Kreuzstift_Small_RGB_B.svg';
import RosengartenSmallRgbB from '../../assets/logos/rosengarten/Rosengarten_Small_RGB_B.svg';
import TaborSmallRgbB from '../../assets/logos/tabor/Tabor_Small_RGB_B.svg';
import WäckerlingSmallRgbB from '../../assets/logos/wäckerling/Wäckerling_Small_RGB_B.svg';
import ZumiparkSmallRgbB from '../../assets/logos/zumipark/Zumipark_Small_RGB_B.svg';
import averecuraSmallRgbB from '../../assets/logos/averecura/averecura_RGB_B_Small.svg';
import RivabellaSmallRgbB from '../../assets/logos/rivabella/Rivabella_Small_RGB_B.svg';
import BaumaSmallRgbB from '../../assets/logos/bauma/Bauma_Small_RGB_B.svg';
import ImpulsSmallRgbB from '../../assets/logos/impuls/Impuls_Small_RGB_B.svg';
import GeerenSmallRgbB from '../../assets/logos/geeren/Geeren_Small_RGB_B.svg';

export default function Favicon({company}) {
  let source = averecuraSmallRgbB;


    switch (company) {
      case 'digallo':
        source = averecuraSmallRgbB;
        break;
      case 'brunisberg':
        source = BrunisbergSmallRgbB;
        break;
      case 'kreuzstift':
        source = KreuzstiftSmallRgbB;
        break;
      case 'rosengarten':
        source = RosengartenSmallRgbB;
        break;
      case 'tabor':
        source = TaborSmallRgbB;
        break;
      case 'waeckerling':
        source = WäckerlingSmallRgbB;
        break;
      case 'zumipark':
        source = ZumiparkSmallRgbB;
        break;
      case 'rivabella':
        source = RivabellaSmallRgbB;
        break;
      case 'bauma':
        source = BaumaSmallRgbB;
        break;
      case 'impuls':
        source = ImpulsSmallRgbB;
        break;
      case 'geeren':
        source = GeerenSmallRgbB;
        break;
}

useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = source;
  }, []);


  let favicon = (
    <link rel="icon" href={source} type="image/svg+xml" />
  )

  return (
  	favicon
  );
}


Favicon.propTypes = {
    /** Link the Logo redirects to */
    company: PropTypes.oneOf(['digallo', 'brunisberg', 'kreuzstift', 'rosengarten', 'tabor', 'waeckerling', 'zumipark', 'rivabella', 'impuls', 'geeren', 'bauma', '']),
  }