import {React, useContext, useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import bootstrap from 'bootstrap';

import PropTypes from 'prop-types';
import { CompanyContext, ConfigContext, ConfigLoadedContext, LangContext } from '../../context/context'
import { debounce } from '../../helpers/helpers';


import Logo from '../Logo/Logo';
import Icon from '../Icon/Icon';

import styles from './Navigation.module.css'

export default function Navigation({}){

  const [lang, setLang] = useContext(LangContext);
  const config = useContext(ConfigContext);
  const configLoaded = useContext(ConfigLoadedContext);
  const company = useContext(CompanyContext);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [show, setShow] = useState(false);


   const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;

    setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 15) || currentScrollPos < 10);

    setPrevScrollPos(currentScrollPos);
    // timer set to 100 milliseconds:
  }, 50);


  // new useEffect:
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);

  }, [prevScrollPos, visible, handleScroll]);


  const toggleOffCanvas = () => {
    setShow((show) => !show);
  };

  /* PLACEHOLDER / LOADING STATE*/
  let Navigation = (
      <div>
        <div className={`${styles.navContainer} placeholder placeholder-wave col-1`}>
          <span className={styles.logo}>
           <Logo
            company={company}
            link="/"
            type="color-black"
            height="45px"
          />
          </span>
          <nav className={styles.navItems} id="navBarDesktop">
            <p className="placeholder col-1 placeholder-sm m-2"></p>
            <p className="placeholder col-1 placeholder-sm m-2"></p>
            <p className="placeholder col-1 placeholder-sm m-2"></p>
            <p className="placeholder col-1 placeholder-sm m-2"></p>
            <p className="placeholder col-1 placeholder-sm m-2"></p>
          </nav>
          <span className={styles.mobileBurger}>
            <a className={styles.navItem} data-bs-toggle="offcanvas" href="#mobileNav" role="button" aria-controls="offcanvasExample">
              <span className="placeholder col-12"></span>
            </a>
          </span>
        </div>

        {/* MOBILE NAV */}   
        <div className={`${show? 'show' : ""} offcanvas offcanvas-start`} tabIndex="-1" id="mobileNav" aria-labelledby="Navigation">
          <div className="offcanvas-header">
            <span className="placeholder col-12"></span>
            <button type="button" onClick={toggleOffCanvas} className="btn-close" aria-label="Close"></button>
          </div>
            <div className="offcanvas-body"> 
              <nav className={styles.mobileNav}>
                <span className="placeholder col-12"></span><span className="placeholder col-12"></span><span className="placeholder col-12"></span><span className="placeholder col-12"></span>
              </nav>
            </div>
          </div>
        </div>
    )



  let items = []
  let mobileItems = []


  if (configLoaded === true) {
    let navItems = config.Nav 

    navItems.forEach((navItem, index) => {
      items.push(
        <NavLink  key={index} className={styles.navItem}  to={"/"+navItem.link}>{navItem.name}</NavLink>
        )
      mobileItems.push(
        <NavLink key={index} reloadDocument="true" className={styles.navMobileItem} to={"/"+navItem.link}>{navItem.name}</NavLink>
        )
    });


 Navigation = (
    <div>
      <div className={`${styles.navContainer  } ${visible ? "navVisible" : "fadeNav"}`}>
        <span className={styles.logo}>
          <Logo
            company={company}
            link="/"
            type="color-black"
            height="45px"
          />
        </span>
        <nav className={styles.navItems} id="navBarDesktop">
          {items}
          {company == "rivabella" ? (
          <div className={styles.languageButton}>
            <button className={styles.languageButtonTrigger} type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <Icon icon="web" size="1.9em" />
            </button>
            <ul className={`${styles.languageMenu} dropdown-menu min-auto`}>
              <li><a class="dropdown-item" onClick={() => setLang('de-CH')}>DE</a></li>
              <li><a class="dropdown-item" onClick={() => setLang('it-CH')}>IT</a></li>
              <li><a class="dropdown-item" onClick={() => setLang('fr-CH')}>FR</a></li>
              <li><a class="dropdown-item" onClick={() => setLang('en')}>EN</a></li>
            </ul>
          </div>
          ) : ""}
        </nav>
        <span className={styles.mobileBurger}>
          <a className={styles.navItemBurger} data-bs-toggle="offcanvas" href="#mobileNav" role="button" aria-controls="offcanvasExample">
            <Icon icon="list" size="1.9em" />
          </a>
        </span>
      </div>


      {/* MOBILE NAV */}   
      <div className="offcanvas offcanvas-start" tabIndex="-1" id="mobileNav" aria-labelledby="Navigation">
        <div className="offcanvas-header">
          <Logo
            company={company}
            link="/"
            type="color-black"
            height="45px"
          />
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
          <div className="offcanvas-body">
            <nav className={styles.mobileNav}>
              {mobileItems}
            </nav>
          </div>
        </div>
      </div>
    )
  }

  return (
  	Navigation
  );
}



Navigation.propTypes = {
  /** Composition of the task */
    /** Company */
    company: PropTypes.string,
    /** NavItems. */
    navItems: PropTypes.PropTypes.array, 
  }