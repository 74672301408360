import React from 'react';
import PropTypes from 'prop-types';

import styles from './Loading.module.css'

export default function Loading({ }){
  return (
  	<div className={`${styles.wrapper} placeholder placeholder-wave`}>
    </div>
  );
}


Loading.propTypes = {}
