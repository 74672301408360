import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import Icon from '../Icon/Icon';
import styles from './Button.module.css'

export default function Button({ loading, link, text, icon, target, internal }) {
  let button
  if (loading) {
    button = (
      <a disabled className={styles.button}>
        <span className={styles.dummyCircle}>
        </span>
        <span className={styles.iconCircle}>
          <span className={styles.icon}>
            <Icon icon={icon}/>
          </span>
        </span>
        <span className={`${styles.dummyText}`}>
          <span className="placeholder placeholder-wave"/>
        </span>
        <span className={`${styles.dummyText}`}>
          <span className="placeholder placeholder-wave col-12"/>
        </span>
      </a>
    )
  } else {
    if (internal) {
      button = (
        <Link to={link} target={target} className={styles.button}>
          <span className={styles.dummyCircle}>
          </span>
          <span className={styles.iconCircle}>
            <span className={styles.icon}>
              <Icon icon={icon}/>
            </span>
          </span>
          <span className={styles.dummyText}>
          {text}
          </span>
          <span className={styles.textSpan}>
          {text}
          </span>
        </Link>
      )
    } else {
       button = (
        <a href={link} target={target} className={styles.button}>
          <span className={styles.dummyCircle}>
          </span>
          <span className={styles.iconCircle}>
            <span className={styles.icon}>
              <Icon icon={icon}/>
            </span>
          </span>
          <span className={styles.dummyText}>
          {text}
          </span>
          <span className={styles.textSpan}>
          {text}
          </span>
        </a>
      )
    }
  } 

  return (
  	button
  );
}

Button.propTypes = {
    /** Text displayed in the Button */
    text: PropTypes.string.isRequired,
    /** Link the Button redirects to */
    link: PropTypes.string,
    /** Uses React Link instead of href */
    internal: PropTypes.bool,
    /** Icon */
    icon: PropTypes.string.isRequired,
    /** Set Target of Button */
    target: PropTypes.oneOf(['', '_blank', '_self' ]),
  }